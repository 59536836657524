<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Download Intercom Help Centers Documents" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="flex flex-col gap-2">
        <div v-if="reloadAllDocuments">
          <label>Download all documents from Intercom Help Center</label>
          <Message :closable="false" severity="warn">
            This operation will download all documents from Intercom Help Center and update them in Knowledge Base. It will override changes that were made in Knowledge Base directly.
          </Message>
        </div>
        <div v-else>
          <label>Download documents from Intercom Help Center updated since {{ lastUpdated }}</label>
          <Message :closable="false" severity="info">
            This operation will download only documents created or changed in Intercom Help Centers since last update, and update them in Knowledge Base. Updates made in Intercom Help Center will override changes that
            were made in Knowledge Base directly.
          </Message>
        </div>
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button label="Start" :disabled="!startEnabled || showProgress" severity="primary" @click="startDownload" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressBar from 'primevue/progressbar';
  import ScrollPanel from 'primevue/scrollpanel';
  import Message from 'primevue/message';

  const kbStore = useKbStore();

  const toast = useToast();

  const visible = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');

  const reloadAllDocuments = ref(false);
  const lastUpdated = ref('Never');

  const startEnabled = ref(true);

  function start(reloadAll = false, lastUpdateDate = 'Never') {
    reloadAllDocuments.value = reloadAll;
    lastUpdated.value = lastUpdateDate;
    visible.value = true;
  }

  const pause = (x) => new Promise((res) => setTimeout(res, x));

  async function startDownload() {
    showProgress.value = true;
    progressText.value = 'Downloading documents...';

    const updateRes = await kbStore.updateKbDataSources({
      ds: 'intercomhc',
      reloadAll: reloadAllDocuments.value,
    });

    if (!updateRes.success) {
      toast.error(`Error downloading documents: ${updateRes.error}`);
    } else {
      const documentsLoaded = updateRes?.data?.intercomhc?.documentsLoaded || 0;
      const documentsUpdated = updateRes?.data?.intercomhc?.documentsUpdated || 0;
      const documentsErrors = updateRes?.data?.intercomhc?.documentsErrors || 0;
      if (documentsLoaded <= 0) {
        const msg = reloadAllDocuments.value ? 'No documents were found to download' : 'No new or updated documents were found to download';
        toast.info(msg);
      } else if (documentsErrors === 0) {
        toast.success(`Downloaded and updated ${documentsLoaded} documents`);
      } else {
        toast.warning(`Downloaded ${documentsLoaded} documents, updated ${documentsUpdated} documents, ${documentsErrors} documents had errors while updating`);
      }
    }

    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
  }

  defineExpose({ start });
</script>
<style>
  .p-message-wrapper {
    align-items: start;
  }
  .p-message-icon {
    margin-top: 4px;
  }
</style>
