<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-10" @close="emit('cancel')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <form class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-slate-200/50 px-4 py-6 sm:px-6 flex items-center">
                      <div class="flex-auto flex items-center space-x-5">
                        <div class="flex-shrink-0">
                          <div class="relative bg-violet-600/70 rounded-xl p-1">
                            <mdicon :name="icon" size="50" class="text-violet-100" aria-hidden="true"></mdicon>
                          </div>
                        </div>
                        <div class="pt-1.5">
                          <h1 class="text-2xl font-bold text-gray-900">{{ collectionItemsName }}</h1>
                          <p class="text-sm font-medium text-gray-500">Fill in the information below</p>
                        </div>
                      </div>
                      <div class="flex items-start justify-between space-x-3">
                        <div class="flex h-7 items-center">
                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="emit('cancel')">
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="mx-6 mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div v-for="field in fields" :key="field.name" class="col-span-full">
                        <div v-if="field.type !== 'not-editable'">
                          <label :for="field.name" class="block text-sm font-medium leading-6 text-gray-900">{{ field.title }}</label>
                          <div v-if="field.type === 'string'" class="mt-2">
                            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input
                                :id="field.name"
                                :name="field.name"
                                type="text"
                                v-model="documentContent[field.name]"
                                class="block flex-1 pl-3 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div v-else-if="field.type === 'text'" class="mt-2">
                            <textarea
                              :id="field.name"
                              :name="field.name"
                              rows="3"
                              v-model="documentContent[field.name]"
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <div v-else-if="field.type === 'boolean'" class="mt-2">
                            <Checkbox 
                              :id="field.name"
                              :name="field.name"
                              :binary="true"
                              v-model="documentContent[field.name]"
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3">
                      <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="emit('cancel')">Cancel</button>
                      <button
                        type="button"
                        class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        @click="onSave"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  // Sample of form validation: https://github.com/deyemiobaa/tailwindcss_custom_form_validation/blob/main/index.html
  import { ref, reactive, watch, onMounted } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';
  import Checkbox from 'primevue/checkbox'
  //import dayjs from 'dayjs';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    new: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default() {
        return {};
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    icon: {
      type: String,
      default: 'dots-hexagon',
    },
    collectionItemsName: {
      type: String,
      default: 'Item',
    },
  });

  const emit = defineEmits(['cancel', 'save']);

  const documentContent = reactive({});

  watch(
    () => props.document,
    () => {
      init();
    }
  );

  function init() {
    documentContent.id = props.document ? props.document.id : 'none';
    for (let i = 0; i < props.fields.length; i++) {
      const field = props.fields[i];
      documentContent[field.name] = props.document ? props.document[field.name] || field?.default || '' : field?.default || '';
    }
  }

  function onSave() {
    const updatedDocument = JSON.parse(JSON.stringify(documentContent));
    emit('save', updatedDocument);
  }

  onMounted(() => {
    init();
  });
</script>
