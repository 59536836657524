<template>
  <div class="mx-6 mt-6 relative">
    <div class="md:flex md:items-center md:justify-between md:space-x-5 pb-2 mb-1">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div :class="['relative bg-violet-600/70 rounded-xl p-1', recordingIsPlaying ? 'pulse' : '']">
            <mdicon name="forum-outline" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="mb-1 text-2xl font-bold text-gray-900">Conversation</h1>
          <div>ID: {{ conversation.id }}</div>
        </div>
      </div>
      <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="cog-clockwise" @click="onAnalyze"></ProgressButton>
      <!--<div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onExport"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Report
        </button>
      </div>-->
    </div>

    <TabsResponsive v-model:tabs="tabs" v-model:show-all="showAllTabsContent"></TabsResponsive>

    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Summary</h2>
    <CSatConversationSummary v-if="tabs[0].active" :conversation="conversation"></CSatConversationSummary>
    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Problems</h2>
    <ConversationProblems v-if="tabs[1].active" :problems="analysisProblems" :title="null"></ConversationProblems>
    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">KPI</h2>
    <ConversationKPIs v-if="tabs[2].active" :kpis="analysisKPIs" :title="null"></ConversationKPIs>
    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Agent</h2>
    <ConversationAgentSummary v-if="tabs[3].active" :conversation="conversation"></ConversationAgentSummary>
    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Transcript</h2>
    <ConversationTranscriptPlayer
      :class="[tabs[4].active ? '' : 'hidden']"
      v-model:recording-is-playing="recordingIsPlaying"
      :title="null"
      :conversation="conversation"
      :transcript-messages-path="['context', 'transcript', 'data']"
    ></ConversationTranscriptPlayer>

    <BlockUI :blocked="uiBlocked" fullScreen />
    <div v-if="progressSpinner" class="absolute inset-0 z-100 flex flex-col items-center justify-center h-screen">
      <ProgressSpinner></ProgressSpinner>
      <div v-if="isAnalyzing" class="px-8 py-4 text-white rounded-xl bg-black/60">
        <div class="text-2xl">Analyzing, please wait ...</div>
        <div class="text-md">Analysis may take several minutes</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, computed, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useAuthStore } from '@/store/AuthStore';
  import { useConversationSearchStore } from '@/store/ConversationSearchStore';
  import { useSettingsStore } from '@/store/SettingsStore';
  import { useToast } from 'vue-toastification';
  import { useApi } from '@/hooks/useApi';
  import CSatConversationSummary from '@/components/CSat/CSatConversationSummary.vue';
  import ConversationTranscript from '@/components/Insights/ConversationTranscript.vue';
  import ConversationTranscriptPlayer from '@/components/Insights/ConversationTranscriptPlayer.vue';
  import ConversationProblems from '@/components/Insights/ConversationProblems.vue';
  import ScenarioResultsKPIs from '@/components/Scenarios/ScenarioResultsKPIs.vue';
  import ConversationKPIs from '@/components/Insights/ConversationKPIs.vue';
  import ConversationAgentSummary from '@/components/Insights/ConversationAgentSummary.vue';
  import { ConversationDetailsReport } from '@enegelai/core';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import BlockUI from 'primevue/blockui';
  import ProgressSpinner from 'primevue/progressspinner';
  import TabsResponsive from '@/components/Tabs/TabsResponsive.vue';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';

  dayjs.extend(utc);

  const route = useRoute();
  const authStore = useAuthStore();
  const conversationSearchStore = useConversationSearchStore();
  const settingsStore = useSettingsStore();
  const toast = useToast();
  const api = useApi();

  const analyzeProgressButton = ref(null);
  const isAnalyzing = ref(false);

  const tenantId = computed(() => authStore.tenantId);
  const qaJobId = computed(() => settingsStore.qaJobId);
  const transcriptQAJobId = computed(() => settingsStore.transcriptQAJobId);
  const conversation = ref({});

  const conversationId = computed(() => conversation.value?.id || '');
  const conversationDatetime = computed(() => conversation.value?.datetime || dayjs().utc().toISOString());
  const conversationDayUTC = computed(() => dayjs(conversationDatetime.value).utc().format('YYYY.MM.DD'));

  const conversationMedia = computed(() => conversation.value?.media || 'recording');
  const conversationMetadataKind = computed(() => conversation.value?.context?.transcript?.metadata?.kind);

  const hasKPI = computed(() => conversation.value?.context?.analysis?.kpis !== undefined);
  const hasProblems = computed(() => conversation.value?.context?.analysis?.problems !== undefined);
  const hasSentiment = computed(() => conversation.value?.context?.analysis?.sentiment !== undefined);
  const hasAgent = computed(
    () =>
      conversation.value?.context?.analysis?.agentStrengths !== undefined ||
      conversation.value?.context?.analysis?.agentWeaknesses !== undefined ||
      conversation.value?.context?.analysis?.agentOpportunities !== undefined,
  );

  const analysisKPIs = computed(() => conversation.value.context?.analysis?.kpis || []);
  const analysisProblems = computed(() => conversation.value.context?.analysis?.problems || []);

  const recordingIsPlaying = ref(false);

  const uiBlocked = ref(false);
  const progressSpinner = ref(false);

  const tabs = ref([
    { name: 'Summary', href: '#', icon: 'chart-box-outline', active: true, hidden: false },
    { name: 'Problems', href: '#', icon: 'alert-box-outline', active: false, hidden: true },
    { name: 'KPI', href: '#', icon: 'playlist-check', active: false, hidden: true },
    { name: 'Agent', href: '#', icon: 'face-agent', active: false, hidden: true },
    { name: 'Transcript', href: '#', icon: 'play-box-outline', active: false, hidden: false },
  ]);
  const tabsHidden = computed(() => [false, !hasProblems.value, !hasKPI.value, !hasAgent.value, false]);
  const showAllTabsContent = ref(false);

  // Watch conversation change and update hidden ones based on conv props
  watch(conversation, (newValue) => {
    // Update tabs visibility to reflect analyzed data available in the conversation
    tabs.value = tabs.value.map((x, idx) => {
      return Object.assign({}, x, { hidden: tabsHidden.value[idx] });
    });
  });

  // Export report
  function onExport() {
    const reports = ConversationDetailsReport.generateResultReports(conversation.value);
    downloadReport(`Conversation_${conversationId.value}`, reports.reportHtml, 'text/html');
  }

  function downloadReport(filename, report, mimeType) {
    // credit: https://www.bitdegree.org/learn/javascript-download
    let text = report;
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(text));
    element.setAttribute('download', filename);
    //element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async function onAnalyze() {
    console.log(`onAnalyze`);
    let jobId;
    let fn;
    const convDate = conversationDayUTC.value; //'2024.01.16';

    // Prepare parameters
    switch (conversationMedia.value) {
      case 'recording':
        jobId = qaJobId.value; //'yhuzIAzvu8';
        fn = `${tenantId.value}/calls/${convDate}/${conversationId.value}.mp3`;
        break;
      case 'transcript': {
        jobId = transcriptQAJobId.value;
        const prefix = conversationMetadataKind.value ? `${conversationMetadataKind.value}.` : '';
        fn = `${tenantId.value}/transcripts/${convDate}/${prefix}${conversationId.value}.transcript.json`;
        break;
      }
      default:
        toast.error(`Unexpected media type ${conversationMedia.value}.`);
        stopProgress();
        return;
    }

    if (!jobId) {
      toast.error('Analysis configuration is missing, please contact administrator.');
      stopProgress();
      return;
    }
    startProgress();

    const params = {
      jobId: jobId,
      jobParameters: {
        files: [fn],
      },
    };
    const jobExecuteResult = await api.executeJobOnDemand(params);
    console.log(`jobExecuteResult:`, jobExecuteResult);
    if (!jobExecuteResult.success) {
      toast.error(`Error while analyzing: ${jobExecuteResult.error}`);
      stopProgress();
      return;
    }
    conversation.value = await conversationSearchStore.getConversation(conversationId.value, true);
    stopProgress();
    toast.info(`Analysis complete`);
  }

  function startProgress(analyzeProgress = true) {
    if (analyzeProgress) {
      isAnalyzing.value = true;
      analyzeProgressButton.value.startProgress();
    }
    uiBlocked.value = true;
    progressSpinner.value = true;
  }
  function stopProgress(analyzeProgress = true) {
    progressSpinner.value = false;
    uiBlocked.value = false;
    if (analyzeProgress) {
      isAnalyzing.value = false;
      analyzeProgressButton.value.stopProgress();
    }
  }

  onMounted(async () => {
    startProgress(false);
    conversation.value = await conversationSearchStore.getConversation(route.params.conversationId);
    stopProgress(false);
  });
</script>
