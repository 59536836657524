<template>
  <CollectionDocsCategoriesKPIs collection-type="categories" icon="list-box-outline" collection-items-name="Categories" collection-subtitle="A list of all categories" :fields="collectionDocFields"></CollectionDocsCategoriesKPIs>
</template>
<script setup>
  import CollectionDocsCategoriesKPIs from '@/components/Collections/CollectionDocsCategoriesKPIs.vue';
  const collectionDocFields = [
    { name: 'name', title: 'Name',              type: 'string', tableHide: false, isSelected: false, sortable: true,},
    { name: 'definition', title: 'Definition',  type: 'text', tableHide: false, isSelected: false, sortable: true,},
  ];
</script>
