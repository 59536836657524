/*
 * KB Type definitions and meta-information
 * */

/*
 * Base for all Kb Type Definitions
 * */

export class KbTypeDef {
  constructor() {
    this.type = '';
    this.category = '';
    this.title = '';
    this.description = '';
    this.defaultName = '';
    this.icon = 'lightbulb-on-20';
    this.helpUrl = '';
    this.props = [];
  }
}

export const emptyKbType = new KbTypeDef();

// Common Kb props, applicable for all knowledge bases
export const commonKbProps = [
  // TODO
];

/*
 * Kb Type Definition: Standard Knowledge Base
 * */
export class KbTypeStandard extends KbTypeDef {
  constructor() {
    super();
    this.type = 'standard';
    this.category = 'kb';
    this.title = 'Knowledge Base';
    this.description = 'Stores information used by Assistants';
    this.defaultName = 'New Knowledge Base';
    this.helpUrl = '/help/kb/standard';
    this.icon = 'database-outline';
    this.props = commonKbProps.concat([
      {
        name: 'webSourceEnabled',
        advanced: false,
        type: 'boolean',
        title: 'Enable Web Data Source',
        default: false,
        description: 'Enable loading data from the Web sources, such as your website or online knowledge base.',
      },
      { name: 'sitemaps', advanced: false, type: 'text', title: 'Sitemaps', default: '', description: 'List of sitemap URLs to crawl. Separate sitemaps by new line.' },
      { name: 'urls', advanced: false, type: 'text', title: 'URLs', default: '', description: 'List of individual URLs to crawl. Separate URLs by new line.' },
      {
        name: 'zendeskHelpCenterSourceEnabled',
        advanced: false,
        type: 'boolean',
        title: 'Enable Zendesk Help Center Data Source',
        default: false,
        description: 'Enable loading data from Zendesk Help Centers',
      },
      {
        name: 'zendeskHelpCentersSubdomains',
        advanced: false,
        type: 'string',
        title: 'Zendesk Help Centers Subdomains',
        default: '',
        description: 'Subdomains of Zendesk Help Centers to load data from. Separate subdomains by comma.',
      },
      {
        name: 'zendeskHelpCenterSourceRefreshInterval',
        advanced: true,
        type: 'select',
        title: 'Zendesk Help Center data refresh interval',
        description:
          'When this is enabled, Articles from your Zendesk Help Center will be refreshed in the Knowledge Base as often as selected. This will keep Knowledge base always in sync with the latest updates on your Zendesk Help Center.',
        options: ['Manual', '1 day', '1 week'],
        values: ['0', '86400', '604800'],
        default: '0',
      },
      {
        name: 'intercomHelpCenterSourceEnabled',
        advanced: false,
        type: 'boolean',
        title: 'Enable Intercom Help Center Data Source',
        default: false,
        description: 'Enable loading data from Intercom Help Center',
      },
      {
        name: 'intercomHelpCenterSourceRefreshInterval',
        advanced: true,
        type: 'select',
        title: 'Intercom Help Center data refresh interval',
        description:
          'When this is enabled, Articles from your Intercom Help Center will be refreshed in the Knowledge Base as often as selected. This will keep Knowledge base always in sync with the latest updates on your Intercom Help Center.',
        options: ['Manual', '1 day', '1 week'],
        values: ['0', '86400', '604800'],
        default: '0',
      },
      {
        name: 'automatedUpdatesEnabled',
        advanced: false,
        type: 'boolean',
        title: 'Enable Automated Updates',
        default: false,
        description:
          'With automated updates, information from the Web sources can be refreshed in the Knowledge Base as often as needed. This will keep Knowledge base always in sync with the latest updates on your websites.',
      },
      {
        name: 'mode',
        advanced: true,
        type: 'select',
        title: 'Mode',
        options: ['embedded'],
        values: ['embedded'],
        default: 'embedded',
      },
      {
        name: 'embeddingModel',
        advanced: true,
        type: 'select',
        title: 'Embedding Model',
        options: ['text-embedding-3-large-1536'],
        values: ['text-embedding-3-large-1536'],
        default: 'text-embedding-3-large-1536',
      },
      {
        name: 'vectorStore',
        advanced: true,
        type: 'select',
        title: 'Vector Store',
        options: ['elastic'],
        values: ['elastic'],
        default: 'elastic',
      },
      {
        name: 'splitSizeThreshold',
        advanced: true,
        type: 'number',
        title: 'Document split size threshold',
        default: 2000,
        description: 'Do not split document when indexing in KB if smaller than this size (in characters).',
      },
      //
      // +++
    ]);
    this.propsByName = {};
    this.props.map((p) => {
      this.propsByName[p.name] = p;
    });
  }
}

// All known KB Type Defs by type
export const KB_TYPE_DEFS = {
  standard: new KbTypeStandard(),
};

// Meta-information about all known KB Types
export const KB_TYPE_META = Object.values(KB_TYPE_DEFS).map((x) => {
  return {
    type: x.type,
    title: x.title,
    description: x.description,
    helpUrl: x.helpUrl,
    icon: x.icon,
  };
});

export function getKbTypeDef(kbType) {
  return kbType in KB_TYPE_DEFS ? KB_TYPE_DEFS[kbType] : emptyKbType;
}
