<template>
  <div class="space-y-5 divide-y divide-gray-900/10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">{{ kbStore.kbTypeTitle }}</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Specify name and description for the Knowledge Base</p>
        </div>
      </div>

      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Specify KB Name"
                    v-model="specName"
                  />
                </div>
              </div>
            </div>

            <InputTextarea class="col-span-full" name="description" v-model="specDescription" label="Description" placeholder="Provide brief description of the KB"></InputTextarea>

            <div class="col-span-full">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Tags</label>
              <div class="text-xs text-gray-500">List of Tags that can be set on KB Documents</div>
              <StdTagInputEditor class="mt-2" v-model="specTags" :available-tags="kbStore.kbTags" @add-tag="onKbAddTag"></StdTagInputEditor>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Web Data</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Load data from the Web, for example from your website.</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <ToggleGroup :disabled="true" class="col-span-full" v-model="webSourceEnabled" :name="propsDefs['webSourceEnabled'].title" :description="propsDefs['webSourceEnabled'].description">
            <div class="mt-4 pl-4 border-l-2 border-indigo-100 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
              <InputTextarea
                class="col-span-full"
                name="sitemaps"
                v-model="sitemaps"
                :label="propsDefs['sitemaps'].title"
                :description="propsDefs['sitemaps'].description"
                :placeholder="propsDefs['sitemaps'].placeholder"
                :rows="2"
              ></InputTextarea>

              <InputTextarea
                class="col-span-full"
                name="urls"
                v-model="urls"
                :label="propsDefs['urls'].title"
                :description="propsDefs['urls'].description"
                :placeholder="propsDefs['urls'].placeholder"
                :rows="2"
              ></InputTextarea>
            </div>
          </ToggleGroup>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Zendesk Help Center Data</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Load data from your Zendesk Help Centers</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <ToggleGroup class="col-span-full" v-model="zendeskHelpCenterSourceEnabled" :name="propsDefs['zendeskHelpCenterSourceEnabled'].title" :description="propsDefs['zendeskHelpCenterSourceEnabled'].description">
              <div class="mt-4 col-span-full pl-4 border-l-2 border-indigo-100">
                <StdPropEditor class="mt-2 col-span-4" :prop-def="propsDefs['zendeskHelpCentersSubdomains']" v-model="zendeskHelpCentersSubdomains" />
              </div>
              <div class="pt-6 col-span-full pl-4 border-l-2 border-indigo-100 flex items-center justify-end gap-x-2">
                <Button label="Reload all" icon="pi pi-cloud-download" severity="secondary" @click="onDownloadZendeskHelpCenters(true)" />
                <Button icon="pi pi-cloud-download" label="Load Updates" @click="onDownloadZendeskHelpCenters(false)" />
              </div>
            </ToggleGroup>
          </div>
        </div>
      </div>
    </div>

    <div v-if="settingsStore.intercomIntegrationEnabled" class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Intercom Help Center Data</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Load data from your Intercom Help Centers</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <ToggleGroup class="col-span-full" v-model="intercomHelpCenterSourceEnabled" :name="propsDefs['intercomHelpCenterSourceEnabled'].title" :description="propsDefs['intercomHelpCenterSourceEnabled'].description">
              <div class="pt-6 col-span-full pl-4 border-l-2 border-indigo-100 flex items-center justify-end gap-x-2">
                <Button label="Reload all" icon="pi pi-cloud-download" severity="secondary" @click="onDownloadIntercomHelpCenters(true)" />
                <Button icon="pi pi-cloud-download" label="Load Updates" @click="onDownloadIntercomHelpCenters(false)" />
              </div>
            </ToggleGroup>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Advanced</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Show advanced options</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="col-span-full">
              <ToggleGroup v-model="advancedEnabled" name="Advanced" description="">
                <StdPropEditor class="mt-2 col-span-4" :prop-def="propsDefs['mode']" v-model="mode" />
                <StdPropEditor class="mt-4 col-span-4" :prop-def="propsDefs['embeddingModel']" v-model="embeddingModel" />
                <StdPropEditor class="mt-4 col-span-4" :prop-def="propsDefs['vectorStore']" v-model="vectorStore" />
                <StdPropEditor class="mt-4 col-span-4" :prop-def="propsDefs['splitSizeThreshold']" v-model="splitSizeThreshold" />
              </ToggleGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KbDialogDownloadZendeskHelpCenter ref="dialogDownloadZendeskHelpCenter"></KbDialogDownloadZendeskHelpCenter>
    <KbDialogDownloadIntercomHelpCenter ref="dialogDownloadIntercomHelpCenter"></KbDialogDownloadIntercomHelpCenter>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useKbStore } from '@/store/KbStore';
  import { useSettingsStore } from '@/store/SettingsStore';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import { KB_TYPE_DEFS } from '@/spec/KbTypeDefs';
  import StdPropEditor from '@/components/Editors/StdPropEditor.vue';
  import StdTagInputEditor from '@/components/Editors/StdTagInputEditor.vue';
  import Button from 'primevue/button';
  import KbDialogDownloadZendeskHelpCenter from '@/components/KB/dialogs/KbDialogDownloadZendeskHelpCenter.vue';
  import KbDialogDownloadIntercomHelpCenter from '@/components/KB/dialogs/KbDialogDownloadIntercomHelpCenter.vue';
  import dayjs from 'dayjs';

  const route = useRoute();
  const showAdvanced = computed(() => 'advanced' in (route?.query || {}));
  const kbStore = useKbStore();
  const settingsStore = useSettingsStore();

  const dialogDownloadZendeskHelpCenter = ref(null);
  const dialogDownloadIntercomHelpCenter = ref(null);

  // todo show based on user role
  const advancedEnabled = ref(false);

  const propsDefs = KB_TYPE_DEFS['standard']?.propsByName || {};

  const specName = computed(kbStore.getKbSpecPropGetSet('name'));
  const specDescription = computed(kbStore.getKbSpecPropGetSet('description'));
  const specTags = computed(kbStore.getKbSpecPropGetSet('tags'));

  const webSourceEnabled = computed(kbStore.getKbSpecPropGetSet('webSourceEnabled'));
  const sitemaps = computed(kbStore.getKbSpecPropGetSet('sitemaps'));
  const urls = computed(kbStore.getKbSpecPropGetSet('urls'));

  const zendeskHelpCenterSourceEnabled = computed(kbStore.getKbSpecPropGetSet('zendeskHelpCenterSourceEnabled'));
  const zendeskHelpCenterSourceRefreshInterval = computed(kbStore.getKbSpecPropGetSet('zendeskHelpCenterSourceRefreshInterval'));
  const zendeskHelpCentersSubdomains = computed(kbStore.getKbSpecPropGetSet('zendeskHelpCentersSubdomains'));

  const intercomHelpCenterSourceEnabled = computed(kbStore.getKbSpecPropGetSet('intercomHelpCenterSourceEnabled'));
  const intercomHelpCenterSourceRefreshInterval = computed(kbStore.getKbSpecPropGetSet('intercomHelpCenterSourceRefreshInterval'));

  const automatedUpdatesEnabled = computed(kbStore.getKbSpecPropGetSet('automatedUpdatesEnabled'));

  const mode = computed(kbStore.getKbSpecPropGetSet('mode'));
  const embeddingModel = computed(kbStore.getKbSpecPropGetSet('embeddingModel'));
  const vectorStore = computed(kbStore.getKbSpecPropGetSet('vectorStore'));
  const splitSizeThreshold = computed(kbStore.getKbSpecPropGetSet('splitSizeThreshold'));

  function onKbAddTag(tag) {
    kbStore.addTagToKb(tag);
  }

  function onDownloadZendeskHelpCenters(reloadAll = false) {
    const kb = kbStore.getKb(kbStore.kbId);
    const lastUpdate = kb?.zendeskHelpCenterLastUpdated || null;
    const lastUpdateDate = lastUpdate ? dayjs(lastUpdate).format() : null;

    const subdomains = (kbStore.kbSpec?.zendeskHelpCentersSubdomains || '').split(',').map((x) => x.trim());
    dialogDownloadZendeskHelpCenter.value.start(reloadAll, subdomains, lastUpdateDate);
  }

  function onDownloadIntercomHelpCenters(reloadAll = false) {
    const kb = kbStore.getKb(kbStore.kbId);
    const lastUpdate = kb?.zendeskHelpCenterLastUpdated || null;
    const lastUpdateDate = lastUpdate ? dayjs(lastUpdate).format() : null;

    dialogDownloadIntercomHelpCenter.value.start(reloadAll, lastUpdateDate);
  }
</script>
