import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import * as firebaseui from 'firebaseui';

// Default app config, points to stage
let firebaseConfig = {
  apiKey: 'AIzaSyCbU99PZcBhAAmUSyB6CeV_Zr6cTymjheA',
  authDomain: 'enegel-stage.firebaseapp.com',
  projectId: 'enegel-stage',
  storageBucket: 'enegel-stage.appspot.com',
  messagingSenderId: '379776506088',
  appId: '1:379776506088:web:4d14862c01c8373cbdd15d',
  measurementId: 'G-H0T1ZERMYG',
};

// Prod app config
if (window.location.hostname === 'app.enegel.ai') {
  console.log(`Using prod fb config ${window.location.hostname}`);
  firebaseConfig = {
    apiKey: 'AIzaSyDVvqH90MguFhBC2gey7_5VWdSUEiwsbqQ',
    authDomain: 'enegel-prod.firebaseapp.com',
    projectId: 'enegel-prod',
    storageBucket: 'enegel-prod.appspot.com',
    messagingSenderId: '898787241543',
    appId: '1:898787241543:web:479a357440f6cef33ccb62',
    measurementId: 'G-77VQTFNT2K',
  };
}

export const fbApp = initializeApp(firebaseConfig);
// TODO Clarify
export const fbAnalytics = getAnalytics(fbApp);
export const fbDb = getFirestore();
export const fbAuth = getAuth();
export const fbFunctions = getFunctions();
export const fbStorage = getStorage();

// Enable to use all firebase services simulators locally
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
  connectFirestoreEmulator(fbDb, 'localhost', 8086);
  connectAuthEmulator(fbAuth, 'http://localhost:9099');
  connectFunctionsEmulator(fbFunctions, 'localhost', 5001);
  connectStorageEmulator(fbStorage, 'localhost', 9199);
  // TODO add more services as described in the docs: https://firebase.google.com/docs/emulator-suite/connect_firestore
}

// Make sure it's after emulators setup, otherwise it'll talk to real service
export const fbAuthUI = new firebaseui.auth.AuthUI(fbAuth);