<template>
  <div class="bg-white">
    <div class="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
      <div class="mx-auto max-w-7xl pb-24 sm:pb-32 lg:px-8 lg:py-10">
        <!--<div class="pb-5">
          <h3 class="text-3xl font-semibold leading-6 text-indigo-500">Quick Start</h3>
        </div>-->

        <div class="border-b border-gray-200 pb-5">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">Create AI Assistant</h3>
          <p class="mt-2 max-w-4xl text-sm text-gray-500">
            Assistants use information from your website or documents to provide the best service for your customers. Choose Assistant type that addresses your use case best.
          </p>
        </div>

        <div class="mt-20 sm:mt-24 md:mx-auto lg:mx-0 lg:mt-0 lg:col-span-2">
          <div class="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36" aria-hidden="true" />
          <div class="md:rounded-3xl">
            <div class="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-40 ring-1 ring-inset ring-white md:ml-20 lg:ml-36" aria-hidden="true" />
            <div class="relative">
              <div class="w-full flex flex-wrap">
                <div
                  v-for="(action, actionIdx) in bots"
                  :key="actionIdx"
                  :class="['border border-gray-100 rounded-xl bg-gray-50 overflow-auto mr-4 my-4 p-4 w-[350px] group cursor-pointer relative focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']"
                  @click="onCreateBot(action)"
                >
                  <mdicon :name="action.icon" size="60" class="text-orange-600/70"></mdicon>
                  <!--<mdicon :name="action.icon" size="80" class="z-10 absolute top-0 left-0 text-orange-100" aria-hidden="true"></mdicon>-->
                  <div class="mt-4 relative z-20">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">
                      {{ action.title }}
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">{{ action.description }}</p>
                  </div>
                  <span class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-500" aria-hidden="true">
                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { useBotsStore } from '@/store/BotsStore';
  import { BOT_TYPE_INFO } from '@/const';

  const router = useRouter();
  const botsStore = useBotsStore();

  const bots = Object.keys(BOT_TYPE_INFO)
    .map((k) => BOT_TYPE_INFO[k])
    .filter((x) => x.enabled && x.category === 'bot'); // category ???

  async function onCreateBot(action) {
    const newBotId = await botsStore.createBot(action.type, action.defaultName);
    if (newBotId) {
      await router.push(`/bots/editor/${newBotId}`);
    } else {
      console.log(`Failed to create new Bot`);
    }
  }
</script>
