//import Admin from './layouts/Admin.vue';
import { computed } from 'vue';
import BaseLayout from './layouts/BaseLayout.vue';
import MainLayout from './layouts/MainLayout.vue';
import TestLayout from './layouts/TestLayout.vue';
import FullHeightLayout from '@/layouts/FullHeightLayout.vue';
import FlexTestLayout from '@/layouts/FlexTestLayout.vue';
import NotFound from './views/NotFound.vue';
import Login from '@/views/Auth/Login.vue';
import ValidateEmail from '@/views/Auth/ValidateEmail.vue';
import TestPivot from './views/TestPivot.vue';
import SchedulesView from './views/plan/ShedulesView.vue';
import SpecEditorView from '@/views/Spec/SpecEditorView.vue';
import SpecEditorViewTest2 from '@/views/Spec/SpecEditorViewTest2.vue';
import PerspectiveWorkspace from './components/PerspectiveWorkspace.vue';
import TestComponent from '@/components/TestComponent.vue';
import TestComponent2 from '@/components/TestComponent2.vue';
import AnalysisResults from '@/Analysis/AnalysisProblems.vue';
import AnalysisKPIs from '@/Analysis/AnalysisKPIs.vue';
import AnalysisKPIsCondenced from '@/Analysis/AnalysisKPIsCondenced.vue';
import DatasetEdit from '@/views/DatasetEdit.vue';
import TestResult from '@/components/TestResult/TestResult.vue';
import TestResultSummary from '@/components/TestResult/TestResultSummary.vue';
import TestResultTranscript from '@/components/TestResult/TestResultTranscript.vue';
import ChartsExperiments from '@/components/Charts/ChartsExperiments.vue';
import ChartsExperiments2 from '@/components/Charts/ChartsExperiments2.vue';
import TestsList from '@/components/Tests/TestsList.vue';
import AudioList from '@/components/Audio/AudioList.vue';
import TestRunsList from '@/components/Tests/TestRunsList.vue';
import TestResultView from '@/views/Tests/TestResultView.vue';
import ResultsList from '@/components/Tests/ResultsList.vue';
import ScenariosList from '@/components/Scenarios/ScenariosList.vue';
import ScenarioEdit from '@/components/Scenarios/ScenarioEdit.vue';
import ScenarioResults from '@/components/Scenarios/ScenarioResults.vue';
import Settings from '@/components/Settings/Settings.vue';
import UsersList from '@/components/Settings/UsersList.vue';
import Usage from '@/components/Settings/Usage.vue';
import Integrations from '@/components/Settings/Integrations.vue';
import JobsExecutionsList from '@/components/Jobs/JobsExecutionsList.vue';
import StartHere from '@/components/StartHere.vue';
import JobsList from '@/components/Jobs/JobsList.vue';
import JobsResultsList from '@/components/Jobs/JobsResultsList.vue';
import JobResultsDetails from '@/components/Jobs/JobResultsDetails.vue';
import JobEdit from '@/components/Jobs/JobEdit.vue';
import JobEditor from '@/components/Jobs/Edit/JobEditor.vue';
import TranscriptsCollectionsList from '@/components/Collections/TranscriptsCollectionsList.vue';
import RecordingsCollectionsList from '@/components/Collections/RecordingsCollectionsList.vue';
import KPICollectionsList from '@/components/Collections/KPICollectionsList.vue';
import CategoriesCollectionsList from '@/components/Collections/CategoriesCollectionsList.vue';
import CollectionFiles from '@/components/Collections/CollectionFiles.vue';
import CollectionDocsKPI from '@/components/Collections/CollectionDocsKPI.vue';
import CollectionDocsCategories from '@/components/Collections/CollectionDocsCategories.vue';
import AgentDetails from '@/components/Agents/AgentDetails.vue';
import DbOne from '@/components/Charts/DbOne.vue';
import SearchConversations from '@/components/Insights/SearchConversations.vue';
import ConversationDetails from '@/components/Insights/ConversationDetails.vue';
import FlowEdit from '@/components/Flows/FlowEdit.vue';
import DocumentationFile from '@/components/Documentation/DocumentationFile.vue';
import AgentBase from '@/components/Agents/AgentBase.vue';
import AgentHome from '@/components/Agents/AgentHome.vue';
import AgentStrengths from '@/components/Agents/AgentStrengths.vue';
import AgentWeaknesses from '@/components/Agents/AgentWeaknesses.vue';
import AgentOpportunities from '@/components/Agents/AgentOpportunities.vue';
import AgentCoach from '@/components/Agents/AgentCoach.vue';
import AgentsInsights from '@/components/Insights/AgentsInsights.vue';
import AgentsActivity from '@/components/Insights/AgentsActivity.vue';
import AgentsPerformance from '@/components/Insights/AgentsPerformance.vue';
import TestJobs from '@/components/Jobs/TestJobs.vue';
import AnalysisQuickStart from '@/components/AnalysisQuickStart.vue';
import BotsList from '@/components/Bots/BotsList.vue';
import BotEditor from '@/components/Bots/BotEditor.vue';
import BotsQuickStart from '@/components/Bots/BotsQuickStart.vue';
import BotSettingsEditor from '@/components/Bots/editors/BotSettingsEditor.vue';
import BotActionsEditor from '@/components/Bots/editors/BotActionsEditor.vue';
import BotDataEditor from '@/components/Bots/editors/BotDataEditor.vue';
import BotSearchConversations from '@/components/Bots/analytics/BotSearchConversations.vue';
import BotConversationDetails from '@/components/Bots/analytics/BotConversationDetails.vue';
import BotTest from '@/components/Bots/test/BotTest.vue';
import BotSummary from '@/components/Bots/analytics/BotSummary.vue';
import QuickStart from '@/views/QuickStart.vue';
import CSatSummary from '@/components/CSat/CSatSummary.vue';
import CSatConversations from '@/components/CSat/CSatConversations.vue';
import CSatConversationDetails from '@/components/CSat/CSatConversationDetails.vue';
import CSatAgentsPerformance from '@/components/CSat/CSatAgentsPerformance.vue';
import CSatAgentsScores from '@/components/CSat/CSatAgentsScores.vue';
import CSatAgentsKPIScores from '@/components/CSat/CSatAgentsKPIScores.vue';
import KbQuickStart from '@/components/KB/KbQuickStart.vue';
import KbList from '@/components/KB/KbList.vue';
import KbEditor from '@/components/KB/KbEditor.vue';
import KbSettingsEditor from '@/components/KB/editors/KbSettingsEditor.vue';
import KbDocumentsList from '@/components/KB/KbDocumentsList.vue';
import KbDocumentEditor from '@/components/KB/editors/KbDocumentEditor.vue';
import KbSearch from '@/components/KB/KbSearch.vue';

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: '/', redirect: '/bots' },
  {
    path: '/login',
    component: Login,
    name: 'login',
  },
  {
    path: '/validateemail',
    component: ValidateEmail,
    name: 'validateemail',
  },
  {
    path: '/quickstart',
    component: QuickStart,
    name: 'quickstart',
  },
  {
    path: '/csat',
    component: FlexTestLayout,
    redirect: '/csat/search',
    children: [
      {
        path: '/csat/summary',
        component: CSatSummary,
        name: 'csat.summary',
      },
      {
        path: '/csat/search',
        component: CSatConversations,
        name: 'csat.search',
      },
      {
        path: '/csat/conversation/:conversationId',
        component: CSatConversationDetails,
        name: 'csat.conversation',
      },
      {
        path: '/csat/agentsperformance',
        component: CSatAgentsPerformance,
        name: 'csat.agentsperformance',
      },
      {
        path: '/csat/agentsscores',
        component: CSatAgentsScores,
        name: 'csat.agentsscores',
      },
      {
        path: '/csat/agentskpiscores',
        component: CSatAgentsKPIScores,
        name: 'csat.agentskpiscores',
      },
    ],
  },
  {
    path: '/datasets',
    component: FlexTestLayout,
    redirect: '/datasets/transcripts',
    children: [
      {
        path: '/datasets/transcripts',
        redirect: '/datasets/transcripts/list',
        name: 'datasets.transcripts',
        children: [
          {
            path: '/datasets/transcripts/list',
            component: TranscriptsCollectionsList,
            name: 'datasets.transcripts.list',
          },
          {
            path: '/datasets/transcripts/edit/:id',
            component: CollectionFiles,
            name: 'datasets.transcripts.edit',
            meta: {
              collectionType: 'transcripts',
              allowedFileTypes: ['.json'],
            },
          },
        ],
      },
      {
        path: '/datasets/recordings',
        redirect: '/datasets/recordings/list',
        name: 'datasets.recordings',
        children: [
          {
            path: '/datasets/recordings/list',
            component: RecordingsCollectionsList,
            name: 'datasets.recordings.list',
          },
          {
            path: '/datasets/recordings/edit/:id',
            component: CollectionFiles,
            name: 'datasets.recordings.edit',
            meta: {
              collectionType: 'recordings',
              allowedFileTypes: ['.wav', '.mp3', '.json'],
            },
          },
        ],
      },
      {
        path: '/datasets/kpi',
        redirect: '/datasets/kpi/list',
        name: 'datasets.kpi',
        children: [
          {
            path: '/datasets/kpi/list',
            component: KPICollectionsList,
            name: 'datasets.kpi.list',
          },
          {
            path: '/datasets/kpi/edit/:id',
            component: CollectionDocsKPI,
            name: 'datasets.kpi.edit',
            meta: {
              collectionType: 'kpi',
            },
          },
        ],
      },
      /**/{
        path: '/datasets/categories',
        redirect: '/datasets/categories/list',
        name: 'datasets.categories',
        children: [
          {
            path: '/datasets/categories/list',
            component: CategoriesCollectionsList,
            name: 'datasets.categories.list',
          },
          {
            path: '/datasets/categories/edit/:id',
            component: CollectionDocsCategories,
            name: 'datasets.categories.edit',
            meta: {
              collectionType: 'categories',
            },
          },
        ],
      },/**/
    ],
  },
  {
    path: '/settings',
    component: FlexTestLayout,
    redirect: '/settings/users',
    children: [
      {
        path: '/settings/users',
        component: UsersList,
        name: 'settings.users',
      },
      {
        path: '/settings/usage',
        component: Usage,
        name: 'settings.usage',
      },
      {
        path: '/settings/integrations',
        component: Integrations,
        name: 'settings.integrations',
      },
      {
        path: '/settings/jobs',
        redirect: '/settings/jobs/list',
        name: 'settings.jobs',
        children: [
          {
            path: '/settings/jobs/list',
            component: JobsList,
            name: 'settings.jobs.list',
          },
          {
            path: '/settings/jobs/edit/:id',
            component: JobEdit,
            name: 'settings.jobs.edit',
          },
          {
            path: '/settings/jobs/editor/:id',
            component: JobEditor,
            name: 'settings.jobs.editor',
          },
        ],
      },
    ],
  },
  {
    path: '/insights',
    component: FlexTestLayout,
    redirect: '/insights/search',
    children: [
      {
        path: '/insights/search',
        component: SearchConversations,
        name: 'insights.search',
      },
      {
        path: '/insights/conversation/:conversationId',
        component: ConversationDetails,
        name: 'insights.conversation',
      },
      {
        path: '/insights/agents/performance',
        component: AgentsPerformance,
        name: 'insights.agents.performance',
      },
      {
        path: '/insights/agents/activity',
        component: AgentsActivity,
        name: 'insights.agents.activity',
      },
    ],
  },
  {
    path: '/flows',
    component: FlexTestLayout,
    redirect: '/flows/flow',
    children: [
      {
        path: '/flows/flow',
        component: FlowEdit,
        name: 'flows.flow',
      },
      {
        path: '/flows/search',
        component: SearchConversations,
        name: 'flows.search',
      },
    ],
  },
  {
    path: '/agent',
    component: FlexTestLayout,
    redirect: '/agent/details',
    children: [
      {
        path: '/agent/details',
        component: AgentBase,
        name: 'agent.details',
        redirect: '/agent/details/home',
        children: [
          {
            path: '/agent/details/home',
            component: AgentHome,
            name: 'agent.details.home',
          },
          {
            path: '/agent/details/coach',
            component: AgentCoach,
            name: 'agent.details.coach',
          },
          {
            path: '/agent/details/strengths',
            component: AgentStrengths,
            name: 'agent.details.strengths',
          },
          {
            path: '/agent/details/weaknesses',
            component: AgentWeaknesses,
            name: 'agent.details.weaknesses',
          },
          {
            path: '/agent/details/opportunities',
            component: AgentOpportunities,
            name: 'agent.details.opportunities',
          },
        ],
      },
    ],
  },
  {
    path: '/documentation',
    component: FlexTestLayout,
    redirect: '/documentation/getting-started',
    children: [
      {
        path: '/documentation/:fileName',
        component: DocumentationFile,
        name: 'documentation.file',
      },
    ],
  },
  {
    path: '/bots',
    component: FlexTestLayout,
    redirect: '/bots/quickstart',
    children: [
      {
        path: '/bots/quickstart',
        component: BotsQuickStart,
        name: 'bots.quickstart',
      },
      {
        path: '/bots/list',
        component: BotsList,
        name: 'bots.list',
      },
      {
        path: '/bots/conversations',
        component: BotSearchConversations,
        name: 'bots.conversations',
      },
      {
        path: '/bots/dashboard',
        component: BotSummary,
        name: 'bots.dashboard',
      },
      {
        path: '/bots/conversation/:conversationId',
        component: BotConversationDetails,
        name: 'bots.conversation',
      },
      {
        path: '/bots/editor/:id',
        component: BotEditor,
        name: 'bots.editor',
        redirect: { name: 'bots.editor.settings' },
        children: [
          {
            path: '/bots/editor/:id/settings',
            component: BotSettingsEditor,
            name: 'bots.editor.settings',
          },
          {
            path: '/bots/editor/:id/actions',
            component: BotActionsEditor,
            name: 'bots.editor.actions',
          },
          {
            path: '/bots/editor/:id/data',
            component: BotDataEditor,
            name: 'bots.editor.data',
          },
          {
            path: '/bots/editor/:id/test',
            component: BotTest,
            name: 'bots.editor.test',
          },
        ],
      },
    ],
  },
  {
    path: '/kb',
    component: FlexTestLayout,
    redirect: '/kb/quickstart',
    children: [
      {
        path: '/kb/quickstart',
        component: KbQuickStart,
        name: 'kb.quickstart',
      },
      {
        path: '/kb/list',
        component: KbList,
        name: 'kb.list',
      },
      {
        path: '/kb/dashboard',
        component: BotSummary,
        name: 'kb.dashboard',
      },
      {
        path: '/kb/editor/:id',
        component: KbEditor,
        name: 'kb.editor',
        redirect: { name: 'kb.editor.documents' },
        children: [
          {
            path: '/kb/editor/:id/settings',
            component: KbSettingsEditor,
            name: 'kb.editor.settings',
          },
          {
            path: '/kb/editor/:id/documents',
            component: KbDocumentsList,
            name: 'kb.editor.documents',
          },
          {
            path: '/kb/editor/:id/document/:documentId',
            component: KbDocumentEditor,
            name: 'kb.editor.document.editor',
          },
          {
            path: '/kb/editor/:id/search',
            component: KbSearch,
            name: 'kb.editor.search',
          },
        ],
      },
    ],
  },
  {
    path: '/analysis',
    component: FlexTestLayout,
    redirect: '/analysis/quickstart',
    children: [
      {
        path: '/analysis/quickstart',
        component: AnalysisQuickStart,
        name: 'layouts.quickstart',
      },
      {
        path: '/analysis/starthere',
        component: StartHere,
        name: 'layouts.starthere',
      },
      {
        path: '/analysis/spec',
        component: SpecEditorViewTest2,
        name: 'layouts.spec',
      },
      {
        path: '/analysis/jobs',
        redirect: '/analysis/jobs/list',
        name: 'layouts.jobs',
        children: [
          {
            path: '/analysis/jobs/list',
            component: JobsList,
            name: 'layouts.jobs.list',
          },
          {
            path: '/analysis/jobs/edit/:id',
            component: JobEdit,
            name: 'layouts.jobs.edit',
          },
          {
            path: '/analysis/jobs/editor/:id',
            component: JobEditor,
            name: 'layouts.jobs.editor',
          },
        ],
      },
      {
        path: '/analysis/testruns',
        redirect: '/analysis/testruns/list',
        name: 'layouts.testruns',
        children: [
          {
            path: '/analysis/testruns/list',
            component: TestRunsList,
            name: 'layouts.testruns.list',
          },
          {
            path: '/analysis/testruns/result/:id',
            component: TestResultView,
            name: 'layouts.testruns.result',
          },
        ],
      },
      {
        path: '/analysis/jobexecutions',
        component: JobsExecutionsList,
        name: 'layouts.jobexecutions',
      },
      {
        path: '/analysis/results',
        redirect: '/analysis/results/list',
        name: 'layouts.results',
        children: [
          {
            path: '/analysis/results/list',
            component: JobsResultsList,
            name: 'layouts.results.list',
          },
          {
            path: '/analysis/results/details/:id',
            component: JobResultsDetails,
            name: 'layouts.results.details',
          },
          {
            path: '/analysis/results/details/:id/:scenarioId',
            component: ScenarioResults,
            name: 'layouts.results.details.scenario',
          },
        ],
      },
      {
        path: '/analysis/scenarios',
        redirect: '/analysis/scenarios/list',
        name: 'layouts.scenarios',
        children: [
          {
            path: '/analysis/scenarios/list',
            component: ScenariosList,
            name: 'layouts.scenarios.list',
          },
          {
            path: '/analysis/scenarios/edit/:id',
            component: ScenarioEdit,
            name: 'layouts.scenarios.edit',
          },
        ],
      },
    ],
  },
  {
    path: '/components',
    component: FullHeightLayout,
    redirect: '/components/flow',
    children: [
      {
        path: '/components/jobtest',
        component: TestJobs,
        name: 'components.jobtest',
      },
      {
        path: '/components/flow',
        component: FlowEdit,
        name: 'components.flow',
      },
      {
        path: '/components/results',
        component: AnalysisResults,
        name: 'components.results',
      },
      {
        path: '/components/kpis',
        component: AnalysisKPIs,
        name: 'components.kpis',
      },
      {
        path: '/components/kpiscondenced',
        component: AnalysisKPIsCondenced,
        name: 'components.kpiscondenced',
      },
      {
        path: '/components/spec',
        component: SpecEditorView,
        name: 'components.spec',
      },
      {
        path: '/components/pivot',
        component: TestPivot,
        name: 'components.pivot',
      },
      {
        path: '/components/dashboard',
        component: PerspectiveWorkspace,
        name: 'components.dashboard',
      },
      {
        path: '/components/dsedit',
        component: DatasetEdit,
        name: 'components.dsedit',
      },
      {
        path: '/components/testdetails',
        component: TestResult,
        name: 'components.testdetails',
      },
      {
        path: '/components/testtranscript',
        component: TestResultSummary,
        name: 'components.testtranscript',
      },
      {
        path: '/components/test',
        component: TestComponent,
        name: 'components.test',
      },
      {
        path: '/components/charts',
        component: ChartsExperiments,
        name: 'components.charts',
      },
      {
        path: '/components/charts2',
        component: ChartsExperiments2,
        name: 'components.charts2',
      },
      {
        path: '/components/dbone',
        component: DbOne,
        name: 'components.dbone',
      },
      {
        path: '/components/search',
        component: SearchConversations,
        name: 'components.search',
      },
      {
        path: '/components/conversation/:conversationId',
        component: ConversationDetails,
        name: 'components.conversation',
      },
    ],
  },
  {
    path: '/tests',
    component: TestLayout,
    redirect: '/tests/schedules',
    children: [
      {
        path: '/tests/schedules',
        component: SchedulesView,
        name: 'tests.schedules',
      },
    ],
  },
  { path: '/:path(.*)', component: NotFound },
];
